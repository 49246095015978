
function ModuleCard({ module }) {
  const moduleStyles = {
    backgroundColor: 'black', 
    color: 'white', 
    border: '1px solid white',
    borderRadius: '5px',
    minWidth: '250px',
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

  
    return (
    <div style={moduleStyles} className="module-card">
      <h3>{module.title}</h3>
      <p>{module.description}</p>
    </div>
  );
}

export default ModuleCard;