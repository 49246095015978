

const button_style = {
    backgroundColor: 'blue',
    color: 'white',
    padding: '0.5rem 1rem',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
}

export default button_style;