
import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { useNavigate  } from 'react-router-dom';

function DashboardHeader() {

  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const logoutRedirect = () => {
    logout();
    navigate("/");
  }

  const headerStyles = {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    backgroundColor: 'black',
    margin: 0, 
    width: '100%',
    flex: '0', 
    borderBottom: '1px solid white',
  }

  const buttonStyle = {
    backgroundColor: 'black',
    color: 'white',
    border: '1px solid white',
    borderRadius: '5px',
    padding: '5px'
  }


  return (
    <div style={headerStyles}>
      <div style={{paddingLeft:'10px'}}>
        <button 
          style={buttonStyle}
          onClick={logoutRedirect}>
          Logout
          </button>
      </div>
      <div>
        <h1>Smart home dashboard</h1>
      </div>
      <div>
      </div>
    </div>
  );
}

export default DashboardHeader;