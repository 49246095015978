import React from 'react';
import { useState, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './AuthContext';

import ProtectedRoute from './pages/protected_route';
import LoginPage from './pages/login/login_page';
import DashboardPage from './pages/dashboard/dashboard_page';


function App() {

  const [isAuth, setIsAuth] = useState(false);

  const login = () => {
    setIsAuth(true);
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>   
          <Route path="/" element={<LoginPage />} />
          <Route path="/dashboard" element={
              <ProtectedRoute >
                <DashboardPage />
              </ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
