
import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

import button_style from '../../global_styles/button_style';


const API_URL = "https://api.jakubstetina.cz/auth/login";


function LoginBox() {

  const { login } = React.useContext(AuthContext);
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");


  const navigate = useNavigate();

  const login_box_styles = { 
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: 'rgb(214, 214, 214)',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const sendLoginRequest = async() => {
    console.log("Sending login request...");
    setLoading(true);
    try {
      const reqData = {
        "user" : username, 
        "password" : password,
      }

      const response = await axios.post(API_URL, reqData);
      const data = response.data

      if (data.response === "OK") {
        console.log("Login successful");
        login();
        navigate("/dashboard");
      } else {
        setMessage("Login failed");
        console.log("Login failed");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  
    return (
      <form style={login_box_styles}>
        <h1 style={{
          margin: 0,
          marginTop: '1.5rem',
          padding: 0
        }}>Log in</h1>
        <p style={{
          color: 'red',
          fontSize: '0.8rem',
          margin: 0,
        }}>{message}</p>
        <input type="text" onChange={(e) => setUsername(e.target.value)} placeholder="username" />
        <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="password" />
        <button 
          style={button_style}
          onClick={sendLoginRequest}
          disabled={loading}>
              Log in
          </button>
      </form>
  );
}

export default LoginBox;