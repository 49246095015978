
import DashboardHeader from "./header";
import DashboardBody from "./body";

function DashboardPage() {
  const dashboardPageStyles = { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  }

  return (
    <div style={dashboardPageStyles}>
      <DashboardHeader />
      <DashboardBody />
    </div>
  );
}

export default DashboardPage;