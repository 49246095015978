import LoginBox from "./login_box";

function LoginPage() {
    const loginPageStyles = {
        height: '100vh',
        width: '100vw',
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(100, 100, 100)',
    }

    return (
        <div style={loginPageStyles}>
            <LoginBox />
        </div>
    )
}

export default LoginPage;
