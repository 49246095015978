
import ModuleCard from "./moduleCard";

function DashboardBody() {
  const DashboardBodyStyles = { 
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    color: 'white',
    backgroundColor: 'black',
  }

  const GridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridTemplateRows: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '30px',
    padding: '10px',
  }

  const module1 = { 
    title: 'Module 1',
    description: 'This is module 1',
  }

  const module2 = { 
    title: 'Module 2',
    description: 'This is module 2',
  }

  const testModules = [module1, module2];

  return (
    <div style={DashboardBodyStyles}>
      <div style={GridStyles}>
        {testModules.map((module, index) => (
          <ModuleCard key={index} module={module} />
        ))}
      </div>
    </div>
  );
}


export default DashboardBody;
