
import React, { useState, createContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuth, setAuth] = useState(false);
    const [apiToken, setApiToken] = useState(null);

    const login = () => {
        setAuth(true);
    }

    const logout = () => {
        setAuth(false);
    }

    return (
        <AuthContext.Provider value={{isAuth, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}